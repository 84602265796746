import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"
import Video from "../components/video"
import Heading from "../components/heading"
import Gallery from "../components/gallery"
import Paragraph from "../components/paragraph"
import { container } from "../components/common.module.css"

const Page = props => {
  const blocks = props.data.wpPage.blocks
  const { title, slug } = props.data.wpPage
  return (
    <Layout page={slug}>
      <Seo title={title} />
      <>
      
      {blocks.map((block, index) => {

      if (block.name === "cgb/gj-banner") {
        return <Banner innerBlocks={block.innerBlocks} key={block.name} />
      }
      if (block.name === "core/embed") {
        return <Video attributes={block.attributes} key={block.name} />
      }
      if (block.name === "core/heading") {
        return <Heading attributes={block.attributes} key={block.name} />
      }
      if (block.name === "core/gallery") {
        return (
          <Gallery attributes={block.attributes} key={block.name + index} />
        )
      }

      if (block.name === "cgb/gj-paragraph") {
        return (<section className={container} style={{textAlign: 'justify'}}>
          <p>
            <strong>Gj Systemy</strong> prowadzi swoją działalność na terenie całego kraju. Firma nasza działa na rynku od 2017 roku. Zatrudniamy doświadczonych i wykwalifikowanych fachowców, których cechuje niepowtarzalna precyzja i profesjonalne podejście do każdego zlecenia.
          </p>
          <p>
            W ofercie w ramach malowania natryskowego oferujemy malowanie hydrodynamiczne (bezpowietrzne). Przy użyciu specjalistycznego sprzętu wykonujemy malowanie natryskowe hydrodynamiczne na najwyższym poziomie. Głównie zajmujemy się malowaniem antykorozyjnym i p-poż. farbami pęczniejącymi.
          </p>
          <p>
            Nasza firma oferuje kompleksowe rozwiązania w zakresie malowania hydrodynamicznego. Zaletą takiego malowania jest wysoka wydajność malowania. W porównaniu z natryskiem pneumatycznym, pozwala ona oszczędzić więcej materiału malarskiego dzięki czemu uzyskujemy prawidłową powłokę, grubszą jej warstwę  oraz mniejsze zapylenie i stężenie oparów rozpuszczalników.
          </p>
          <p>
            Oferujemy również prace poprawkowe (zlecone) na terenie budowy.
          </p>
          <p>
          Dysponujemy agregatami malarskimi firm Graco, które gwarantują najwyższą jakość malowania powierzonych elementów. Agregaty te mają bardzo dużą wydajność, a silniki elektryczne bez-szczotkowe pozwalają malować nawet przy samym elemencie bez ryzyka zapłonu.
          </p>
          <p>
            Wszystkim naszym partnerom zapewniamy usługi na najwyższym poziomie. Zastosowany przez naszą firmę <strong>Gj Systemy</strong> system malowania jest niezwykle dokładny. Farby natryskowe stanowią efektowne wykończenie każdego elementu, co znacząco podnosi standard i atrakcyjność zlecenia. Jest to tylko jeden z wielu powodów, dla których cieszą się one wciąż rosnącą popularnością.
          </p>
          <p>
            Oferta docelowa naszej firmy <strong>Gj Systemy</strong> jest bardzo atrakcyjna i dostosowujemy ją do klienta. 
          </p>
          <p>
            Zachęcam Państwa do współpracy
          </p>
          <br />
          <h3>Lista firm dla których wykonywalismy zlecenia:</h3>
          <p>
            Daldehog<br />
            Kaufland<br />
            Instal Białystok<br />
            Toyota<br />
            Alpe<br />
            Impostal<br />
            Tarmetal<br />
            Castorama<br />
            Metalbark<br />
            TLC<br />
            MKL Bau<br />
            Voltz<br />
            SVT<br />
            Mirbud<br />
            <br />
          </p>
      </section>)
      }

      if (block.name === "cgb/gj-gallery") {
        return (
          <Gallery
            heading={block.innerBlocks.find(
              innerBlock => innerBlock.name === "core/heading"
            )}
            images={block.innerBlocks.filter(
              innerBlock => innerBlock.name === "core/image"
            )}
            key={block.name + index}
          />
        )
      }

      return null
      })}
      </>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    wpPage(id: { eq: $id }) {
      id
      title
      slug
      blocks {
        name
        ... on WpCgbGjBannerBlock {
          innerBlocks {
            ... on WpCoreImageBlock {
              name
              attributes {
                ... on WpCoreImageBlockAttributes {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
              }
            }
            ... on WpCoreHeadingBlock {
              name
              attributes {
                ... on WpCoreHeadingBlockAttributes {
                  content
                }
              }
            }
            ... on WpCoreButtonBlock {
              name
              attributes {
                ... on WpCoreButtonBlockAttributes {
                  text
                  url
                }
              }
            }
          }
        }
        ... on WpCgbGjGalleryBlock {
          innerBlocks {
            ... on WpCoreImageBlock {
              name
              attributes {
                ... on WpCoreImageBlockAttributes {
                  id
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
            ... on WpCoreHeadingBlock {
              name
              attributes {
                ... on WpCoreHeadingBlockAttributes {
                  content
                }
              }
            }
          }
        }
        ... on WpCoreEmbedBlock {
          attributes {
            ... on WpCoreEmbedBlockAttributes {
              url
            }
          }
        }
        ... on WpCoreHeadingBlock {
          name
          attributes {
            ... on WpCoreHeadingBlockAttributes {
              content
            }
          }
        }
      }
    }
  }
`

export default Page
