import React from "react"

import { container } from "./common.module.css"
import { heading } from "./heading.module.css"

export default function Heading({ attributes }) {
  return (
    <div className={container}>
      <h2 className={heading}>{attributes.content}</h2>
    </div>
  )
}
