import React from "react"
import Masonry from "react-masonry-css"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { container } from "./common.module.css"
import {
  heading as headingClass,
  myMasonryGrid,
  myMasonryGrid_column,
} from "./gallery.module.css"

export default function Gallery({ heading, images }) {
  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1,
  }

  return (
    <SimpleReactLightbox>
      <div className={container}>
        <h2 className={headingClass}>{heading.attributes.content}</h2>

        <SRLWrapper>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className={myMasonryGrid}
            columnClassName={myMasonryGrid_column}
          >
            {images.map(image => {
              const { localFile, id } = image.attributes
              const img = getImage(localFile)
              return <GatsbyImage image={img} className={img} />
            })}
          </Masonry>
        </SRLWrapper>
      </div>
    </SimpleReactLightbox>
  )
}
