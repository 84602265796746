import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  banner,
  bannerImage,
  bannerContent,
  bannerHeading,
  bannerButton,
} from "./banner.module.css"

export default function Banner({ innerBlocks }) {
  const image = innerBlocks.find(block => block.name === "core/image")
  const heading = innerBlocks.find(block => block.name === "core/heading")
  const buttons = innerBlocks.filter(block => block.name === "core/button")

  return (
    <div className={banner}>
      {image && (
        <GatsbyImage
          image={image.attributes.localFile.childImageSharp.gatsbyImageData}
          alt="Banner"
        />
      )}
      <div className={bannerContent}>
        {heading && (
          <h1 className={bannerHeading}>{heading.attributes.content}</h1>
        )}
        {buttons &&
          buttons.map(button => {
            if (button.attributes.url.includes("tel")) {
              return (
                <a
                  href={button.attributes.url}
                  className={bannerButton}
                  key={button.attributes.url}
                >
                  {button.attributes.text}
                </a>
              )
            }
            return (
              <Link
                to={button.attributes.url}
                className={bannerButton}
                key={button.attributes.url}
              >
                {button.attributes.text}
              </Link>
            )
          })}
      </div>
    </div>
  )
}
