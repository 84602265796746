import * as React from "react"
import {
  paragraph
} from "./header.module.css"
import { container } from "./common.module.css"

function Paragraph ({ attributes = {} }) {
  console.log(attributes)
  return (
    <div className={container}>
      <p className={paragraph}>{attributes.content}</p>
    </div>
  )
}

export default Paragraph
