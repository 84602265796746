import React from "react"

import { container } from "./common.module.css"
import { video } from "./video.module.css"

export default function Video({ attributes }) {
  return (
    <div className={container}>
      <h2 style={{textAlign: 'center', marginTop: '60px'}}>Malowanie u klienta na hali</h2>
      <iframe
        src={attributes.url}
        id="ytplayer"
        className={video}
        type="text/html"
        title="Video"
        frameBorder="0"
        allowFullScreen
        width="1360"
        height="770"
      />
    </div>
  )
}
